@import '_variables';

.readmore-btn {
    display: block;
    color: $primary-fontcolor;
    border: 1px solid $primary-fontcolor;
    padding: 10px 30px;
    margin: 15px 0;
    font-size: 1em;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        background: $primary-color;
        color: $tertiary-color;
    }
}

@media only screen and (min-width: 992px) {
    .readmore-btn {
        padding: 10px 20px;
        margin: 15px 0;
        text-decoration: none;
    }
}
