@import '../../scss/_variables';
@import '../../scss/_mixins';

.header__rightContainer {
    display: flex;
    width: 50%;
    background-color: $secondary-color;
}

.header__rightone {
    width: 50%;
    display: flex;
    flex-direction: column;

    > div:nth-child(1) {
        width: 100%;
        height: 180px;
        display: flex;
        > p {
            position: absolute;
            z-index: 100px;
            top: 0;
            left: calc((100vw - 245px) / 2);
            font-size: 3.5em;
            font-family: $hero-fontfamily;
        }
    }

    > div:nth-child(2) {
        height: 100%;
    }

    > div:nth-child(3) {
        display: flex;
        height: 20%;
    }
}

.davinciquote__container {
    display: flex;
    flex-direction: column;

    p {
        margin-left: 10px;
        font-size: 1em;
        line-height: 0.5em;
        color: $hero-fontcolor;
        font-family: $hero-fontfamily;
        font-weight: 200;
    }

    > p:nth-child(4) {
        font-size: 0.55em;
        text-transform: capitalize;
    }

    > div {
        margin: auto auto 0 0;
        align-self: flex-end;
    }
}

.header__right-downblock {
    background-color: $tertiary-color;
    @include downblock($tertiary-color);
    right: 0;
    align-self: flex-end;
}

.header__righttwo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 50%;
    height: 500px;
}

.header_right-socialblock {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    height: 80px;
    width: 30px;
    background-color: $primary-color;
    margin-top: -40px;

    a {
        color: $socialicon-color;
        :hover {
            font-size: x-large;
            color: $secondary-color;
        }
    }
}

@media only screen and (min-width: 414px) {
    .header__rightone {
        > div:nth-child(1) {
            height: 200px;
            > p {
                font-size: 4em;
                left: calc((100vw - 284px) / 2);
            }
        }
    }

    .davinciquote__container {
        p {
            font-size: 1.5em;
            line-height: 0.6em;
        }
        > p:nth-child(4) {
            font-size: 0.75em;
        }
    }
}

@media only screen and (min-width: 576px) {
    .header__rightone {
        > div:nth-child(1) {
            height: 290px;
            > p {
                font-size: 5em;
                left: calc((100vw - 355px) / 2);
            }
        }
    }

    .davinciquote__container {
        //height: 30%;
    }
}

@media only screen and (min-width: 768px) {
    .davinciquote__container {
        height: 100%;
    }
}

@media (min-width: 992px) {
    .header__rightone {
        > div:nth-child(1) {
            height: 380px;
            > p {
                font-size: 6em;
                left: calc((100vw - 390px) / 2);
            }
        }
    }
    .davinciquote__container {
        height: 50%;
    }
}
