//font-family: "Rubik", sans-serif;

$primary-color: #2a2a2a;
$secondary-color: #f6f6f6;
$tertiary-color: #fff;
$socialicon-color: #888888;
$semidark-color: #4f4f4f;

$hero-fontcolor: #2a2a2a;
$primary-fontcolor: #2b2b2b;

$menu-fontfamily: 'Roboto', sans-serif;
$page-fontfamily: 'Open Sans', sans-serif;
$hero-fontfamily: 'Open Sans', sans-serif;
