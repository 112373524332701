@import '../../scss/_variables';
@import '../../scss/_mixins';

.pageheader__rightContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $secondary-color;

    > div:nth-child(1) {
        display: block;
        background-color: $tertiary-color;
        width: 100%;
        height: 55%;
        text-align: right;

        img {
            max-width: 100%;
        }
    }

    > div:nth-child(2) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-content: flex-end;
        height: 45%;

        div {
            align-self: flex-end;
            p {
                margin: 0;
                background-color: $primary-color;
            }
        }

        p {
            align-self: flex-end;
            font-size: 5rem;
            margin: auto 10px 20px auto;
            font-family: $page-fontfamily;
            background-color: $secondary-color;
        }
    }
}

.pageheader-image {
    // position: absolute;
    //z-index: 100px;
    //right: 0;
    width: 400px;
    height: auto;
}

@media (min-width: 992px) {
    .pageheader__rightContainer {
        width: 50%;

        > div:nth-child(2) {
            div p {
                margin: 0;
                background-color: white;
            }
        }
    }
}
