@import '../../scss/_variables';
@import '../../scss/_mixins';

.header__leftContainer {
    display: flex;
    //display: none;
    justify-content: space-between;
    width: 50%;
    background-color: $tertiary-color;
}

.header__leftone {
    display: none;
    width: 40%;
    padding-left: 50px;
}

.logonav__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 200px;
}

.logo__container {
    display: flex;
    background-color: $secondary-color;
    justify-content: center;
    width: 100%;

    //max-width: 200px;
    height: 120px;
    text-align: right;
}

.logo {
    width: 120px;
    height: auto;
    margin-top: 30px;
}

%header__lefttwo {
    flex-direction: column;
    width: 100%;
    //height: 100%;

    > div {
        height: 100%;
        // width: 0;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
}

.header__lefttwo-home {
    display: flex;
    @extend %header__lefttwo;
}

.header__lefttwo-others {
    display: none;
    @extend %header__lefttwo;
}

.header__left-downblock {
    background-color: $primary-color;
    @include downblock($primary-color);
}

@media only screen and (min-width: 576px) {
    //this class hide div just viewport is under 500px

    .header__lefttwo-home,
    .header__lefttwo-others {
        display: flex;
        > div {
            width: 100%; //width: 100%;
        }
    }
}

@media only screen and (min-width: 768px) {
}

@media (min-width: 992px) {
    .header__leftContainer {
        display: flex;
    }

    .header__leftone {
        display: flex;
        width: 40%;
        padding-left: 50px;
    }

    .header__lefttwo .header__lefttwo-others {
        width: 60%;
    }
}
