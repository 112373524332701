@import '../../scss/_variables';

header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.header-desktop {
    display: flex;
    position: relative;
    width: 100%;
    height: 600px;
}

@media (min-width: 992px) {
    header {
        flex-direction: row;
    }
}
