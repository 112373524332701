@import '_variables';

.full-section {
    display: block;
    margin: 10px 0px 60px 0px;
    font-family: $page-fontfamily;
    font-weight: 300;
    line-height: 2em;
    font-size: 1em;
    color: $primary-fontcolor;

    p {
        padding-right: 5px;
        padding-left: 5px;
    }
    a {
        color: $primary-fontcolor;
    }
}

.flex-table {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-content {
    width: 100%;
    height: 400px;
    margin: 30px auto;
    object-position: bottom center;
    object-fit: cover;
}

.seprator-empty {
    display: block;
    width: 100%;
    height: 50px;
}

.m-title {
    font-size: 2em;
    font-family: $page-fontfamily;
    color: $primary-fontcolor;
    font-weight: 600;
}

.s-title {
    font-size: 1em;
    font-family: $page-fontfamily;
    color: $primary-fontcolor;
    font-weight: 600;
}

@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 768px) {
    .full-section {
        width: 95%;
        margin: 10px 10px 80px 10px;
        line-height: 2em;
        font-size: 1.24em;

        p {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .m-title {
        font-size: 2em;
    }
}

@media only screen and (min-width: 992px) {
    .full-section {
        width: 95%;
        line-height: 2em;
        font-size: 1.24em;
    }
    .m-title {
        font-size: 2em;
    }
}
