@import '../../scss/_variables';

.projects-container {
    margin: 0 5%;
    font-family: $page-fontfamily;
    font-weight: 300;
    color: $primary-fontcolor;
}

.filter-projects {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.85em;
    padding: 0px;

    li {
        background-color: $secondary-color;
        font-weight: 500;
        font-size: 0.6em;
        margin: 5px;
        padding: 10px 10px;

        &:hover {
            background-color: $hero-fontcolor;
            color: white;
            cursor: pointer;
        }
    }
}

.selected-filter {
    background-color: $primary-color !important;
    color: white !important;
}

.projects-list {
    display: block;
}

.project-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px auto 100px auto;

    > div {
        display: flex;
    }
}

.project-info {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    width: 100%;
}

.project-image {
    width: 100%;
    height: auto !important;
    border: 1px solid $secondary-color;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    align-self: center;

    &:hover {
        cursor: pointer;
        -webkit-filter: none;
        filter: none;
    }
    &:focus {
        cursor: pointer;
        -webkit-filter: none;
        filter: none;
    }
}

.project-name {
    margin: 20px auto 0px 0px;
    font-size: 1.45em;
    font-weight: 400;
}

.project-desc {
    margin: 5px auto;
    font-size: 0.85em;
    font-weight: 200;
    line-height: 1.45em;
}

.project-technologies {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0px;
    margin: 30px auto;
    list-style: none;
    font-size: 0.65em;

    > li {
        display: inline-flex;
        background-color: $secondary-color;
        line-height: 2em;
        padding: 0px 10px;
        margin: 0px 2px;
    }
}

.project-urls {
    display: flex;
    justify-content: flex-start;
    font-size: 0.65em;

    > a {
        display: flex;
        justify-content: space-around;
        align-items: center;

        width: 200px;
        height: 40px;
        margin: 10px 5px;
        border: 1px solid $secondary-color;
        padding: 10px 10px;

        &:link,
        &:visited {
            color: $primary-fontcolor;
        }

        &:hover {
            background-color: $secondary-color;
        }
    }
}

.globe-icon,
.github-icon {
    height: 20px;
    width: 20px;
}

@media only screen and (min-width: 768px) {
    .filter-projects {
        li {
            font-size: 0.85em;
            padding: 10px 20px;
        }
    }

    .project-technologies {
        > li {
            line-height: 2em;
            padding: 0px 10px;
            margin: 0px 2px;
        }
    }

    .project-row {
        display: flex;
        flex-direction: column;

        > div:nth-child(2) {
            padding-left: 10px;
        }
    }
    .project-image {
        width: 100%;
    }

    .project-name {
        margin: 30px auto 10px;
        font-size: 1.75em;
        font-weight: 600;
    }

    .globe-icon,
    .github-icon {
        height: 24px;
        width: 24px;
    }

    .project-urls {
        font-size: 0.85em;

        > a {
            width: 200px;
            height: 50px;
            margin: 10px 20px auto 0px;
            border: 1px solid $secondary-color;
            padding: 10px 10px;
        }
    }
}

@media only screen and (min-width: 992px) {
    .filter-projects {
        li {
            font-size: 1em;
            padding: 10px 20px;
        }
    }

    .project-image {
        width: 400px;
    }

    .project-name {
        margin: 0px auto;
        font-size: 1.75em;
        font-weight: 600;
    }

    .project-row {
        display: flex;
        flex-direction: row;
        > div:nth-child(2) {
            padding-left: 30px;
        }
    }

    .project-urls {
        > a {
            margin-right: 10px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .project-image {
        width: 500px;
    }
}
